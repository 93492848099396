@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  /* font-size: 14px; */
}

:root {
  --ww: #f8f3ea;
  --fgrey: rgba(16, 15, 15, 0.377108);
  --fgrey2: #7e90b2;
  --fred: #ff7e7e;
  --fgreen: #42df94;
  --primary: #2e4c83;
  --bgdark: rgba(20, 7, 53, 0.4);
  --fdark: #21176e;
}

.bg-dark1 {
  background: linear-gradient(
    32.48deg,
    #201b93 -4.82%,
    #221240 87.44%,
    #221240 87.44%
  );
}

/* scroll */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  height: 100%;
  width: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-white {
  color: white;
  fill: white;
}

.f-primary {
  color: var(--primary);
}
.f-grey {
  color: var(--fgrey);
}

.f-dark {
  color: var(--fdark);
}

.f-grey2 {
  color: var(--fgrey2);
}

.f-red {
  color: var(--fred);
}
.f-green {
  color: var(--fgreen);
}

.f-capitalize {
  text-transform: capitalize;
}

.f-uppercase {
  text-transform: uppercase;
}

.f-bold {
  font-weight: bold;
}

.f-w300 {
  font-weight: 300;
}

.f-w700 {
  font-weight: 700;
}

.bg-ww {
  background-color: var(--ww);
}

.bg-dark {
  background-color: var(--bgdark);
}

.bg-glass {
  background: rgba(255, 255, 255, 0.08);
}

.img-fill {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
